import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Login from "./common/Login";
import Chat from "./common/Chat";
import Spinner from "./common/Spinner";
import noDisponible from "./common/noDisponible";
import notFound from "./common/notFound";
import "./App.css";

const App = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={Chat} />
        <Route path="/spinner" component={Spinner} />
        <Route path="/nodisponible" component={noDisponible} />
        <Route path="/*" component={notFound} />
      </Switch>
    </HashRouter>
  );
};

export default App;
