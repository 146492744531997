import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import queryString from "query-string";
import SimpleReactValidator from "simple-react-validator";
import "./Login.css";

const MAXLENGTH = 100;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    const parsed = queryString.parse(props.location.search);

    if (parsed.data) {
      /* 
      const text = Buffer.from(parsed.data, "base64");      
      
      const searchParams = new URLSearchParams(text.toString());

      console.log("email " + searchParams.get("email"));
      console.log("password " + searchParams.get("password"));
      console.log("idlocal " + searchParams.get("idlocal"));
      console.log("panellogin " + searchParams.get("panellogin"));
      */
      this.enviarToken(parsed.data);
    }

    this.state = {
      email: "",
      password: "",
      showErrors: false,
      errors: "",
      showPassword: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.enviarToken = this.enviarToken.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.checkUser = this.checkUser.bind(this);

    this.checkUser();
  }

  handleChange = prop => event => {
    const el = document.querySelector("#email").value;
    const elp = document.querySelector("#password").value;

    const selector = document.querySelector("#span-email");
    const selectorp = document.querySelector("#span-password");

    if (el !== undefined && el.length > 0) {
      if (selector.classList.contains("focus-input")) {
        selector.classList.remove("focus-input");
      }
    } else if (el !== undefined && el.length === 0) {
      if (!selector.classList.contains("focus-input")) {
        selector.classList.add("focus-input");
      }
    }

    if (elp !== undefined && elp.length > 0) {
      if (selectorp.classList.contains("focus-input")) {
        selectorp.classList.remove("focus-input");
      }
    } else if (elp !== undefined && elp.length === 0) {
      if (!selectorp.classList.contains("focus-input")) {
        selectorp.classList.add("focus-input");
      }
    }
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  checkUser() {
    fetch("/api/user/", {
      method: "GET"
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        if (json.status !== "Failed") {
          this.props.history.push(`/dashboard`);
        }
      })
      .catch(error => {});
  }

  enviarToken(data) {
    fetch("/api/token?data=" + data, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        console.log(responseJson);
        const json = JSON.parse(responseJson);
        if (json.status === "Success") {
          //console.log(json.message);
          window.localStorage.setItem("idUser", json.idUser[0]);
          window.localStorage.setItem("idLocal", json.idLocal);
          this.setState({ showErrors: false, errors: "" });
          this.props.history.push(`/dashboard`);
          //this.props.history.push(`/dashboard?data=` + data);
        } else {
          //this.setState({ showErrors: true, errors: json.message });
          this.render();
        }
      })
      .catch(error => {
        //this.setState({ showErrors: true, errors: error });
        console.error(error);
        this.render();
      });
  }

  onPressForm = e => {
    e.preventDefault();

    //console.log(this.state.email);
    //console.log(this.state.password);

    if (this.validator.allValid()) {
      const { email, password } = this.state;
      const user = {
        email: email,
        password: password,
        idlocal: "12"
      };

      fetch("/api/signin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
      })
        .then(response => {
          return response.text();
        })
        .then(responseJson => {
          //console.log(responseJson);
          const json = JSON.parse(responseJson);
          if (json.status === "Success") {
            //console.log(json.message);
            window.localStorage.setItem("idUser", json.idUser[0]);
            window.localStorage.setItem("idLocal", json.idLocal);
            this.setState({ showErrors: false, errors: "" });
            this.props.history.push(`/dashboard`);
          } else {
            this.setState({ showErrors: true, errors: json.message });
            this.render();
          }
        })
        .catch(error => {
          //this.setState({ showErrors: true, errors: error });
          console.error(error);
          this.render();
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  render() {
    const { showErrors, email, password, errors } = this.state;
    return (
      <div className="limiter">
        <div className="container-login">
          <div className="wrap-login p-t-55 p-b-20">
            <form
              onSubmit={this.onPressForm}
              className="login-form validate-form"
            >
              <span className="login-form-title p-b-1200">
                Messaging Center
              </span>

              <span className="login-form-logo">
                <img src="images/indexIcon.png" alt="imagen" />
              </span>

              <div
                className="wrap-input validate-input m-t-85 m-b-35"
                data-validate="Enter email"
              >
                <input
                  className="input"
                  type="email"
                  name="email"
                  value={email}
                  id="email"
                  maxLength={MAXLENGTH}
                  required
                  onChange={this.handleChange("email")}
                />
                {this.validator.message(
                  "email",
                  email,
                  "required|email|max:100"
                )}

                <span
                  id="span-email"
                  className="focus-input"
                  data-placeholder="Email"
                />
              </div>

              <div
                className="wrap-input validate-input m-b-50"
                data-validate="Enter password"
              >
                <input
                  className="input"
                  type="password"
                  value={password}
                  maxLength={MAXLENGTH}
                  onChange={this.handleChange("password")}
                  name="password"
                  required
                  id="password"
                />
                {this.validator.message(
                  "password",
                  password,
                  "required|max:100"
                )}
                <span
                  id="span-password"
                  className="focus-input"
                  data-placeholder="Password"
                />
              </div>

              <div className="container-login-form-btn">
                <button className="login-form-btn">Sign in</button>
              </div>
              {showErrors && (
                <Alert color="warning" fade={false}>
                  {errors}
                </Alert>
              )}
            </form>

            <br />
            <div className="forgot-password">
              <span className="txt1">Forgot&nbsp;&nbsp;</span>

              <a href="#" className="txt2">
                Password?
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Login);
