import React from "react";
import "./noDisponible.css";

class notFound extends React.Component {
  render() {
    return (
      <div className="container">
        <span className="mensaje">Messaging center no disponible</span>

        <span className="logo">
          <img src="images/indexIcon.png" alt="imagen" />
        </span>
      </div>
    );
  }
}

export default notFound;
