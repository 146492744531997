import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import "./Search.css";
import axios from "axios";
const CancelToken = axios.CancelToken;

class Search extends React.Component {
  static propTypes = {
    fetchChats: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      searchResults: [],
      searchQuery: "",
      loading: false,
      localActivo: this.props.localActivo,
      plataforma: this.props.plataforma,
      source: undefined
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ localActivo: nextProps.localActivo });
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        searchQuery: "",
        searchResults: []
      });
    }
  };

  handleChange(event) {
    const searchQuery = event.target.value.toLowerCase();
    const { localActivo, plataforma } = this.state;
    let { source } = this.state;

    this.setState({ searchQuery });

    if (!searchQuery) {
      return false;
    }
    if (this.validator.allValid()) {
      this.setState({ loading: true });

      if (searchQuery.length > 2) {
        if (!source) {
          source = CancelToken.source();
          this.setState({ source });
        } else {
          source.cancel();
          source = CancelToken.source();
          this.setState({ source });
        }

        axios
          .get(
            `/api/customers/customersCompany?id=` +
              localActivo +
              `&plataforma=` +
              plataforma +
              `&name=` +
              searchQuery,
            {
              cancelToken: source.token
            }
          )
          .then(res => {
            //console.log(res);
            this.setState({
              loading: false,
              searchResults: res.data
            });
          })
          .catch(function(thrown) {
            if (axios.isCancel(thrown)) {
            } else {
            }
          });
      } else {
        this.setState({
          searchResults: []
        });
      }
    }
  }

  handleRedirect(idUsuarios) {
    const { localActivo } = this.state;
    this.setState({
      searchQuery: "",
      searchResults: []
    });

    const user = {
      idUsuarios: idUsuarios,
      idLocal: localActivo
    };

    fetch(`/api/conversations/nuevaConversacion`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    })
      .then(response => {
        return response.text();
      })
      .then(result => {
        const json = JSON.parse(result);

        //la conversacion se acaba de crear
        if (json.message === "success") {
          //this.setState({});
          this.props.fetchChats(json.idConversacion, idUsuarios, true);
        }
        // ya existe una conversacion
        else {
          //this.setState({});
          this.props.fetchChats(json.idConversacion, idUsuarios);
        }
        //console.log("result " + JSON.stringify(result));
        //this.props.history.push(`/dashboard`);
      });
  }

  renderSearchResults() {
    const { searchResults, searchQuery, loading } = this.state;

    if (!searchQuery) {
      return false;
    }

    if (searchResults.length > 0) {
      return (
        <div className="search-result-container">
          {searchResults.map(result => (
            <div
              key={result.customers.idUsuarios}
              className="search-result"
              onClick={() => this.handleRedirect(result.customers.idUsuarios)}
            >
              {result.customers.firstName} {result.customers.lastName}
            </div>
          ))}
        </div>
      );
    }

    if (loading && searchQuery.length > 2) {
      return (
        <div className="search-result-container">
          <div className="search-no-result">Buscando ...</div>
        </div>
      );
    }

    if (!loading) {
      return (
        <div className="search-result-container">
          <div className="search-no-result">No results found</div>
        </div>
      );
    }
  }

  render() {
    const { searchQuery } = this.state;
    return (
      <div className="row searchBox">
        <div className="col-sm-12 searchBox-inner">
          <div className="form-group has-feedback">
            <input
              id="searchText"
              type="text"
              className="form-control"
              name="searchText"
              placeholder="Search"
              autoComplete="off"
              onChange={this.handleChange}
              value={searchQuery}
            />
            {this.validator.message("text", searchQuery, "min:2|max:60|string")}
            <span className="glyphicon glyphicon-search form-control-feedback" />
          </div>
        </div>
        {this.renderSearchResults()}
      </div>
    );
  }
}

Search.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Search);
