import React from "react";
import { withRouter } from "react-router-dom";
import "./Chat.css";
import Search from "./Search";
import Spinner from "./Spinner";
import socketIOClient from "socket.io-client";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import md5 from "md5";
import TextareaAutosize from "react-autosize-textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroll-component";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  faCheck,
  faArrowRight,
  faLanguage,
  faUpload,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import ReactDOM from "react-dom";

const SIZE_FILE_MAX = 20971520;
class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.myRef = React.createRef();
    this.myRefImg = {};
    this.textarea = React.createRef();
    this.fileRef = React.createRef();

    this.separador =
      "------------------------------------------------------------------------";

    this.size = 54;

    this.socket = socketIOClient(process.env.REACT_APP_API_DOMAIN);

    this.state = {
      chats: [],
      mensajes: [],
      clientes: {},
      conversacionActiva: "",
      localActivo: window.localStorage.getItem("idLocal"),
      mensaje: "",
      locales: [],
      clienteActivo: "",
      canal: "",
      clients: {},
      loading: true,
      user: window.localStorage.getItem("idUser"),
      mensajeOriginal: "",
      modoNotaPrivada: false,
      whatsappActivo: false,
      hasMore: true,
      totalChats: 0
    };

    this.idioma = window.navigator.language;

    this.mostrarMensajes = this.mostrarMensajes.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.selectChangeCanal = this.selectChangeCanal.bind(this);
    this.consultarNombres = this.consultarNombres.bind(this);
    this.nombre = this.nombre.bind(this);
    this.enviar = this.enviar.bind(this);
    this.enviarMensaje = this.enviarMensaje.bind(this);
    this.enviarMensajeMail = this.enviarMensajeMail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.send = this.send.bind(this);
    this.sendSMS = this.sendSMS.bind(this);
    this.sendChatCliente = this.sendChatCliente.bind(this);
    this.setCanal = this.setCanal.bind(this);
    this.checkUser = this.checkUser.bind(this);
    this.keyCount = 0;
    this.getKey = this.getKey.bind(this);
    this.fetchChats = this.fetchChats.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.actualizarToken = this.actualizarToken.bind(this);
    this.onResize = this.onResize.bind(this);
    this.countMensajesSinLeer = this.countMensajesSinLeer.bind(this);
    this.getChatsNumber = this.getChatsNumber.bind(this);
    this.marcarLeidosMensajesClientes =
      this.marcarLeidosMensajesClientes.bind(this);

    this.actualizarToken();
  }

  inicializacionSocketIO() {
    this.socket.on("connect", () => {
      // Connected, let's sign-up for to receive messages for this room
      this.socket.emit("room", md5(this.state.localActivo));
    });

    this.socket.on("disconnect", reason => {
      console.log("DESCONEXION");
      console.log(reason);
      let canal = '';
      if (this.state.clienteActivo.email) {
        canal = 'email';
      }
      this.setState({
        canal: canal
      });
    });

    this.socket.on("broad", data => {
      let {
        mensajes,
        chats,
        conversacionActiva,
        localActivo,
        clienteActivo
      } = this.state;
      let idConversacion;
      if (data.idConversacion) {
        idConversacion = data.idConversacion;
      }
      delete data.idConversacion;
      if (data.idLocal.toString() === localActivo) {
        let i = 0;
        while (
          i < chats.length &&
          chats[i].conversations.idConversacion !== idConversacion
        ) {
          i++;
        }
        if (data.idCliente.toString() === clienteActivo.idCliente) {
          mensajes.push({
            enviadoPor: data.enviadoPor,
            horaEnvio: data.horaEnvio,
            imgAdjunto: data.imgAdjunto,
            imgPre: data.imgPre,
            mensaje: data.mensaje,
            source: data.source,
            id: data.idMensaje,
            notaPrivada: data.notaPrivada,
            idArchivo: data.idArchivo ? data.idArchivo : undefined,
            tipoAdjunto: data.tipoAdjunto ? data.tipoAdjunto : undefined,
            url: data.url ? data.url : undefined,
            idWhatsapp : data.idWhatsapp ? data.idWhatsapp : undefined
          });
        } else if (i < chats.length) {
          chats[i].conversations.mensajes.push({
            enviadoPor: data.enviadoPor,
            horaEnvio: data.horaEnvio,
            imgAdjunto: data.imgAdjunto,
            imgPre: data.imgPre,
            mensaje: data.mensaje,
            source: data.source,
            id: data.idMensaje,
            notaPrivada: data.notaPrivada,
            idArchivo: data.idArchivo ? data.idArchivo : undefined,
            tipoAdjunto: data.tipoAdjunto ? data.tipoAdjunto : undefined,
            url: data.url ? data.url : undefined,
            idWhatsapp : data.idWhatsapp ? data.idWhatsapp : undefined
          });
        } else {
          chats.push({
            conversations: {
              idCliente: data.enviadoPor,
              idConversacion: idConversacion,
              idLocal: data.idLocal,
              mensajes: [
                {
                  enviadoPor: data.enviadoPor,
                  horaEnvio: data.horaEnvio,
                  imgAdjunto: data.imgAdjunto,
                  imgPre: data.imgPre,
                  mensaje: data.mensaje,
                  source: data.source,
                  id: data.idMensaje,
                  notaPrivada: data.notaPrivada,
                  idArchivo: data.idArchivo ? data.idArchivo : undefined,
                  tipoAdjunto: data.tipoAdjunto ? data.tipoAdjunto : undefined,
                  url: data.url ? data.url : undefined,
                  idWhatsapp : data.idWhatsapp ? data.idWhatsapp : undefined
                }
              ]
            }
          });
          this.consultarNombres(chats[i], chats[i].conversations.idCliente, i);
        }

        const aux = chats[i];
        chats.splice(i, 1);
        chats.unshift(aux);
        this.setState(
          {
            mensajes: mensajes,
            chats,
            user: window.localStorage.getItem("idUser")
          },
          () => {
            if (idConversacion === conversacionActiva) {
              document.getElementById(
                "conversation"
              ).scrollTop = document.getElementById(
                "conversation"
              ).scrollHeight;
              this.marcarLeidosMensajesClientes();
            }
          }
        );
      }
    });

    this.socket.on("clientes", data => {
      let { clientes, canal, clienteActivo, plataforma } = this.state;
      const dataAux = data[plataforma];
      for (var key in dataAux) {
        if (dataAux.hasOwnProperty(key)) {
          if (clientes[key]) {
            clientes[key].ultimaConexion = "En linea";
            if (clienteActivo.idCliente === clientes[key].idCliente) {
              canal = "chat";
            }
          }
        }
      }

      this.setState({
        clients: data,
        clientes: clientes,
        canal: canal
      });
    });

    this.socket.on("marcarLeido", data => {
      let { localActivo, chats } = this.state;
      let idConversacion = data.idConversacion;
      let idMensaje = data.idMensaje;
      if (data.idLocal.toString() === localActivo) {
        let i = 0;
        while (
          i < chats.length &&
          chats[i].conversations.idConversacion !== idConversacion
        ) {
          i++;
        }
        if (i < chats.length) {
          let j = 0;
          while (j < chats[i].conversations.mensajes.length) {
            if (chats[i].conversations.mensajes[j].id === idMensaje) {
              chats[i].conversations.mensajes[j].leido = true;
              break;
            }
            j++;
          }
          this.setState({ chats });
        }
      }
    });

    this.socket.on('marcarLeidoWhatsapp', data => {
      let { localActivo, chats } = this.state;
      const idWhatsapp = data.idWhatsapp;
      if (data.idLocal.toString() === localActivo) {
        let i = 0;
        while (i < chats.length) {
          let j = 0;
          while (j < chats[i].conversations.mensajes.length) {
            if (chats[i].conversations.mensajes[j].idWhatsapp){
            if (chats[i].conversations.mensajes[j].idWhatsapp === idWhatsapp) {
              chats[i].conversations.mensajes[j].leido = true;
              break;
            }}
            j++;
          }
          i++;
        }
        this.setState({ chats });
      }
    });

    this.socket.on("marcarLeidos", data => {
      let { localActivo, chats } = this.state;
      let idCliente = data.idCliente;
      if (data.idLocal.toString() === localActivo) {
        let i = 0;
        while (
          i < chats.length &&
          chats[i].conversations.idCliente !== idCliente
        ) {
          i++;
        }
        if (i < chats.length) {
          for (let j = 0; j < chats[i].conversations.mensajes.length; j++) {
            if (
              chats[i].conversations.mensajes[j].id &&
              chats[i].conversations.mensajes[j].enviadoPor !== idCliente
            ) {
              chats[i].conversations.mensajes[j].leido = true;
            }
          }
          this.setState({ chats });
        }
      }
    });

    this.socket.on("actualizaHora", data => {
      let { clientes, canal } = this.state;
      if (clientes[data.idCliente]) {
        clientes[data.idCliente].ultimaConexion = data.ultimaConexion;
        canal = "email";
      }

      this.setState({ clients: data, clientes: clientes, canal: canal });
    });

  }

  showNotification(titulo, mensaje) {
    try {
      Notification.requestPermission(result => {
        if (result === "granted") {
          navigator.serviceWorker.ready.then(registration => {
            registration.showNotification(titulo, {
              body: mensaje,
              vibrate: [200, 100, 200, 100, 200, 100, 200],
              tag: "notificacion"
            });
          });
        }
      });
    } catch (exception) {}
  }

  actualizarToken() {
    const { user } = this.state;

    fetch("/api/actualizarToken", {
      method: "POST",
      body: JSON.stringify({
        token: window.localStorage.getItem("token-usuario"),
        idChatZone: user
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
      })
      .catch(error => {
      });
  }

  selectChange(event) {
    this.setState(
      {
        conversacionActiva: "",
        mensajes: [],
        chats: [],
        localActivo: event.target.value,
        clienteActivo: "",
        loading: true
      },
      () => {
        this.fetchChats();
      }
    );
  }

  selectChangeCanal(event) {
    this.setState({ canal: event.target.value });
  }

  setCanal(event) {
    this.setState({ canal: event.target.value });
  }

  send = (mensaje, email) => {
    const { user, localActivo, clienteActivo } = this.state;

    this.socket.emit("envioMensajeEmail", {
      idConversacion: this.state.conversacionActiva,
      mensaje: mensaje,
      enviadoPor: user,
      horaEnvio: moment().toISOString(),
      idLocal: localActivo,
      idCliente: clienteActivo.idCliente,
      email: email
    });
  };

  sendNotaPrivada = () => {
    const { user, localActivo, clienteActivo } = this.state;

    this.socket.emit("envioNotaPrivada", {
      idConversacion: this.state.conversacionActiva,
      mensaje: this.textarea.current.value,
      enviadoPor: user,
      horaEnvio: moment().toISOString(),
      idLocal: localActivo,
      idCliente: clienteActivo.idCliente
    });

    this.textarea.current.value = "";
  };

  sendSMS = (mensaje, telefono) => {
    const { user, localActivo, clienteActivo } = this.state;

    this.socket.emit("envioMensajeSMS", {
      idConversacion: this.state.conversacionActiva,
      mensaje: mensaje,
      enviadoPor: user,
      horaEnvio: moment().toISOString(),
      idLocal: localActivo,
      idCliente: clienteActivo.idCliente,
      telefono: telefono
    });
  };

  sendFB = mensaje => {
    const { localActivo, clienteActivo, conversacionActiva } = this.state;
    let URL = "";
    const datos = {
      idLocal: localActivo,
      idUser: clienteActivo.idCliente,
      idEmisor: window.localStorage.getItem("idUser"),
      mensaje,
      conversacion: conversacionActiva
    };

    URL = "/api/sendFB/";
    fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(datos)
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
      })
      .catch(error => {
        this.setState({ showErrors: true, errors: error });
      });
  };

  sendWhatsapp = (mensaje, telefono) => {
    const { user, localActivo, clienteActivo } = this.state;

    this.socket.emit("envioMensajeWhatsApp", {
      idConversacion: this.state.conversacionActiva,
      mensaje: mensaje,
      enviadoPor: user,
      horaEnvio: moment().toISOString(),
      idLocal: localActivo,
      idCliente: clienteActivo.idCliente,
      telefono: telefono
    });
  };


  sendChatCliente = (mensaje, noenviar = false) => {
    const { user, localActivo, clienteActivo, plataforma } = this.state;
    this.socket.emit("responderChat", {
      idConversacion: this.state.conversacionActiva,
      mensaje: mensaje,
      enviadoPor: user,
      source: "chat",
      horaEnvio: moment().toISOString(),
      idLocal: localActivo,
      idCliente: clienteActivo.idCliente,
      noenviar,
      origen: plataforma
    });
  };

  getKey() {
    return this.keyCount++;
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  componentDidMount() {
    this.inicializacionSocketIO();
    this.checkLocal();
    this.getChatsNumber();
    this.fetchChats();
  }

  getChatsNumber() {
    const { localActivo } = this.state;
    let URL = '';
    URL = '/api/conversations/conversations?idLocal=';
    fetch(URL + localActivo, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        if (json.status === 'failed') {
          this.props.history.push(`/login`);
        } else {
          this.setState({ totalChats: json.count_conversaciones });
        }
      })
      .catch(error => {
        this.setState({ showErrors: true, errors: error });
      });
  }

  fetchChats(conversacion = "", cliente = "", nueva = false) {
    const { localActivo, clientes, chats } = this.state;
    let URL = "";
    let URL2 = "";

    URL = "/api/conversations/list?idLocal=";
    URL2 = "/api/conversations/?id=";
    fetch(URL + localActivo + "&page=0", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        if (json.status === "failed") {
          this.props.history.push(`/login`);
        } else {
          if (json.length > 0) {
            if (conversacion === "") {
              this.setState({ chats: json, clientes: {} });
            } else {
              if (nueva) {
                this.getCliente(cliente);
                this.setState({
                  chats: json,
                  conversacionActiva: conversacion,
                  mensajes: []
                });
              } else {
                let i = 0;
                while (
                  i < chats.length &&
                  chats[i].conversations.idConversacion !== conversacion
                ) {
                  i++;
                }
                if (chats[i]) {
                  this.setState({
                    chats: json,
                    conversacionActiva: conversacion,
                    clienteActivo: clientes[cliente],
                    mensajes: chats[i].conversations.mensajes
                  });
                } else {
                  fetch(URL2 + conversacion, {
                    method: 'GET',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                    }
                  })
                  .then(response => {
                    return response.text();
                  })
                  .then(async (responseJson) => {
                    const conversacionNoPaginada = JSON.parse(responseJson);
                    json.push({ conversations: conversacionNoPaginada });
                    await this.getCliente(conversacionNoPaginada.idCliente);
                    this.consultarNombres(conversacion, conversacionNoPaginada.idCliente)
                    this.setState({
                      chats: json,
                      conversacionActiva: conversacion,
                      mensajes: json[i].conversations.mensajes
                    });
                  })
                  .catch(error => {
                    console.log('error '+ error);
                  });
                }
              }
            }
            this.nombre();
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false });
          }
        }
      })
      .catch(error => {
        this.setState({ showErrors: true, errors: error });
      });
  }

  fetchMoreData() {
    const { localActivo, chats, totalChats } = this.state;
    if (this.state.chats.length >= totalChats) {
      this.setState({ hasMore: false });
      return;
    }
    let page = parseInt(this.state.chats.length / 10, 10);
    let URL = '';

    URL = '/api/conversations/list?idLocal=';
    fetch(URL + localActivo + '&page=' + page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        if (json.status === 'failed') {
          this.props.history.push(`/login`);
        } else {
          if (json.length > 0) {
            let arrayChats = chats.concat(json);
            arrayChats = arrayChats.filter((v,i,a)=>a.findIndex(v2=>(v2.conversations.idConversacion===v.conversations.idConversacion))===i);
            this.setState({ chats: arrayChats });
            this.nombre();
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ showErrors: true, errors: error });
      });
  }

  fetchLocales() {
    let URL = '';
    URL = '/api/companies/list';
    fetch(URL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        if (json.status === "failed") {
          this.props.history.push(`/login`);
        } else {
          if (json.length > 0) {
            this.setState({
              locales: json
            });
            this.fetchChats();
          } else {
            this.setState({ showErrors: true, errors: json.message });
          }
        }
      })
      .catch(error => {
        this.setState({ showErrors: true, errors: error });
      });
  }

  mostrarMensajes(idConversacion, cliente) {
    const { chats, clientes, conversacionActiva } = this.state;

    if (this.textarea?.current) {
      this.textarea.current.value = '';
    }

    if (idConversacion !== "undefined" && chats.length > 0) {
      let i = 0;
      while (
        i < chats.length &&
        chats[i].conversations.idConversacion !== idConversacion
      ) {
        i++;
      }

      let valorCanal;
      if (
        clientes[cliente] &&
        this.socket.connected &&
        clientes[cliente].ultimaConexion === "En linea"
      ) {
        valorCanal = "chat";
      } else if (clientes[cliente].email){
        valorCanal = "email";
      } else {
        valorCanal = "";
      }

      if (conversacionActiva) {
        document
          .getElementById(conversacionActiva)
          .classList.remove("conversacion-activa");
      }

      this.setState(
        {
          mensajes: chats[i].conversations.mensajes,
          conversacionActiva: idConversacion,
          clienteActivo: clientes[cliente],
          canal: valorCanal
        },
        () => {
          this.determinarWhatsappActivo();
          document.getElementById(
            "conversation"
          ).scrollTop = document.getElementById("conversation").scrollHeight;
          this.marcarLeidosMensajesClientes();
          document
            .getElementById(idConversacion)
            .classList.add("conversacion-activa");
        }
      );
    }
  }

  handleChangeFile = e => {
    const { user, localActivo, clienteActivo } = this.state;
    const [file] = e.target.files;
    if (file?.size > SIZE_FILE_MAX) {
      alert(
        `El tamaño de archivo supera los ${
          SIZE_FILE_MAX / Math.pow(1024, 2)
        } MB. El tamaño del fichero tiene que ser inferior.`
      );
      return;
    }
    if (!window.confirm(`¿Desea subir el fichero ${file.name}?`)) {
      e.target.value = null;
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      this.socket.emit('envioMensajeWhatsApp', {
        idConversacion: this.state.conversacionActiva,
        enviadoPor: user,
        horaEnvio: moment().toISOString(),
        idCliente: clienteActivo.idCliente,
        telefono: clienteActivo.telefono,
        idLocal: localActivo,
        mensaje: file.name,
        fichero: reader.result
      });
    };
    reader.readAsArrayBuffer(file);
    e.target.value = null;
  };;

  determinarWhatsappActivo() {
    const { mensajes, clienteActivo } = this.state;
    const ultimoMensajeWhatsapp = mensajes
      .filter(element => {
        if (
          element.enviadoPor === clienteActivo.idCliente &&
          element.source === "Whatsapp"
        ) {
          return element;
        }
      })
      .slice(-1)[0]?.horaEnvio;
    if (ultimoMensajeWhatsapp) {
      const whatsappActivo = moment().diff(ultimoMensajeWhatsapp, "hours");
      if (whatsappActivo < 24) {
        this.setState({ whatsappActivo: true, canal: "whatsapp" });
      } else {
        this.setState({ whatsappActivo: false });
      }
    } else {
      this.setState({ whatsappActivo: false });
    }
  }

  cargarImagen(mensaje) {
    fetch("/api/cargarImagen?file=" + mensaje.imgAdjunto, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        this.myRefImg[mensaje.imgAdjunto].src =
          "data:image/jpeg;base64," + json.imagen;
      })
      .catch(error => {
        console.error(error);
      });
  }

  checkLocal() {
    const { localActivo } = this.state;
    let URL = "";
    URL = "/api/companies/?id=";
    return fetch(URL + localActivo, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.status !== 200) {
          this.props.history.push(`/nodisponible`);
          return false;
        }
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        this.setState({ plataforma: json.plataforma });
        return true;
      })
      .catch(error => {
        this.props.history.push(`/nodisponible`);
        return false;
      });
  }

  checkUser() {
    return fetch("/api/user/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {

        const json = JSON.parse(responseJson);
        if (json.status === "failed") {
          this.props.history.push(`/login`);
        }
        return true;
      })
      .catch(error => {
        this.props.history.push(`/login`);
        return false;
      });
  }

  logout() {
    fetch("/api/logout", {
      method: "GET"
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        this.props.history.push(`/`);
      })
      .catch(error => {
      });
  }

  enviar() {
    const { canal, clienteActivo, modoNotaPrivada, whatsappActivo } = this.state;

    if (this.textarea.current.value.trim() === "") {
      return;
    }
    if (!modoNotaPrivada) {
      if (this.validator.allValid()) {
        switch (canal) {
          case "chat":
            if (clienteActivo.ultimaConexion !== "En linea") {
              this.logout();
            } else {
              this.enviarMensajeChatCliente();
            }
            break;
          case "email":
            if (clienteActivo.email) {
              this.enviarMensajeMail();
            } else {
              this.logout();
            }
            break;
          /* case "facebook":
          this.enviarMensajeFB();
          break;
        case "sms":
          if (clienteActivo.telefono) {
            this.enviarMensajeSMS();
          } else {
            this.logout();
          }
          break; */          
          case "whatsapp":
            if (clienteActivo.telefono && whatsappActivo) {
              this.enviarMensajeWhatsapp();
            } else {
              this.logout();
            }
            break;
          default:
        }
      }
    } else {
      this.sendNotaPrivada();
    }
  }

  enviarMensajeFB() {
    const { mensajeOriginal } = this.state;

    if (mensajeOriginal !== "") {
      this.sendFB(
        this.textarea.current.value
          .replace(mensajeOriginal + "\n", "")
          .replace(this.separador + "\n", "")
      );
    } else {
      this.sendFB(this.textarea.current.value);
    }

    this.textarea.current.value = "";
  }

  enviarMensajeSMS() {
    const { clienteActivo, mensajeOriginal } = this.state;

    if (mensajeOriginal !== "") {
      this.sendSMS(
        this.textarea.current.value
          .replace(mensajeOriginal + "\n", "")
          .replace(this.separador + "\n", ""),
        clienteActivo.telefono
      );
    } else {
      this.sendSMS(this.textarea.current.value, clienteActivo.telefono);
    }

    this.textarea.current.value = "";
  }

  enviarMensajeWhatsapp() {
    const { clienteActivo, mensajeOriginal, whatsappActivo } = this.state;

    if (mensajeOriginal !== "" && whatsappActivo) {
      this.sendWhatsapp(
        this.textarea.current.value
          .replace(mensajeOriginal + "\n", "")
          .replace(this.separador + "\n", ""),
        clienteActivo.telefono
      );
    }else {
      this.sendWhatsapp(this.textarea.current.value, clienteActivo.telefono);
    }
    this.textarea.current.value = "";
  }

  enviarMensajeMail() {
    const { clienteActivo, mensajeOriginal, clients, plataforma } = this.state;

    if (mensajeOriginal !== "") {
      this.send(
        this.textarea.current.value
          .replace(mensajeOriginal + "\n", "")
          .replace(this.separador + "\n", ""),
        clienteActivo.email
      );
      if (clients[plataforma] && clients[plataforma][clienteActivo.idCliente]) {
        this.enviarMensajeChatCliente(true);
      }
    } else {
      this.send(this.textarea.current.value, clienteActivo.email);
      if (clients[plataforma] && clients[plataforma][clienteActivo.idCliente]) {
        this.enviarMensajeChatCliente(true);
      }
    }
    this.textarea.current.value = "";
  }

  enviarMensaje() {
    this.send(this.textarea.current.value);
  }

  enviarMensajeChatCliente(noenviar = false) {
    const { mensajeOriginal } = this.state;
    if (mensajeOriginal !== "") {
      this.sendChatCliente(
        this.textarea.current.value
          .replace(mensajeOriginal + "\n", "")
          .replace(this.separador + "\n", ""),
        noenviar
      );
    } else {
      this.sendChatCliente(this.textarea.current.value, noenviar);
    }
    this.textarea.current.value = "";
  }

  async nombre() {
    const { chats } = this.state;
    for (let i = 0; i < chats.length; i++) {
      await this.consultarNombres(
        chats[i],
        chats[i].conversations.idCliente,
        i
      );
    }
    this.setState({ loading: false });
  }

  async getCliente(idCliente) {
    const { plataforma, clientes } = this.state;
    let URL = "";
    URL = "/api/customers/?id=";
    await fetch(URL + idCliente + "&plataforma=" + plataforma, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(responseJson => {
        clientes["" + responseJson.idUsuarios]= responseJson;
        clientes["" + responseJson.idUsuarios].idCliente = "" + responseJson.idUsuarios;
        this.setState({
          clienteActivo: responseJson
        });
        //return responseJson;
      })
      .catch(error => {
        this.setState({ showErrors: true, errors: error });
      });
  }

  consultarNombres(chat, idCliente, i) {
    let { clientes, plataforma } = this.state;

    let URL = "";
    URL = "/api/customers/?id=";
    fetch(URL + idCliente + "&plataforma=" + plataforma, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);
        if (json.status === "failed") {
          this.props.history.push(`/login`);
        } else {
          clientes["" + idCliente.toString()] = {
            idCliente: idCliente.toString(),
            email: json.email,
            firstName: json.firstName,
            nombre: json.nombre,
            lastName: json.lastName,
            telefono: json.telefono,
            nacionalidad: json.nacionalidad,
            locale: json.locale,
            fechaNacimiento: json.fechaNacimiento,
            ciudad: json.ciudad,
            logo: json.logo,
            ultimaConexion: json.ultimaConexion,
            fichaCliente: json.fichaCliente
          };
          this.setState({ clientes });
        }
      })
      .catch(error => {
        this.setState({ showErrors: true, errors: error });
      });
  }

  onResize(event) {
    const conversationSize = document.getElementById("conversation")
      .offsetHeight;
    const responderSize = document.getElementById("responder").offsetHeight;
    const mensajeSize = document.getElementById("mensaje").offsetHeight;
    let tam =
      this.textarea.current.style.height.substring(
        0,
        this.textarea.current.style.height.length - 2
      ) -
      mensajeSize * 0.75;

    if (mensajeSize >= this.size) {
      this.size = mensajeSize;
    } else {
      this.size = mensajeSize;
      tam = (tam / 0.75) * -1;
    }

    if (responderSize < 130) {
      document.getElementById("conversation").style.height =
        conversationSize - tam + "px";
      document.getElementById("responder").style.height =
        responderSize + tam + "px";
      document.getElementById(
        "conversation"
      ).scrollTop = document.getElementById("conversation").scrollHeight;
    }

    if (document.getElementById("mensaje").offsetHeight === 32) {
      document.getElementById("conversation").style.height = null;
      document.getElementById("responder").style.height = null;
    }
  }

  traducirMensajeCliente(id) {
    const { mensajes } = this.state;

    if (!id) {
      return;
    }

    const mensaje = mensajes.find(o => o.id === id);

    fetch("/api/traducir", {
      method: "POST",
      body: JSON.stringify({
        mensaje: mensaje.mensaje,
        idioma: this.idioma
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);

        document.getElementById("traducir" + id).style.display = "none";
        if (responseJson.message !== "sin traducir") {
          const rootElement = document.getElementById(id);
          const elementSpan = React.createElement(
            "span",
            { key: "traducido" + this.getKey(), className: "traducido" },
            json.data
          );
          const elementSpanT = React.createElement(
            "span",
            {
              key: "traducido" + this.getKey(),
              className: "message-time pull-right"
            },
            "Traducido automáticamente"
          );
          const elementDiv = React.createElement(
            "div",
            {
              key: "traducido" + this.getKey(),
              className: "receiver traducido"
            },
            [elementSpan, elementSpanT]
          );
          const element = React.createElement(
            "div",
            {
              key: "traducido" + this.getKey(),
              className: "col-sm-12 message-main-receiver"
            },
            elementDiv
          );
          ReactDOM.render(element, rootElement);
        }
      })
      .catch(error => {
      });
  }

  traducir() {
    const { clienteActivo } = this.state;
    const texto = this.textarea.current.value.split(this.separador);

    if (texto.toString().trim() === '') {
      return;
    }

    let idioma;

    if (clienteActivo.locale) {
      idioma = clienteActivo.locale.substring(0, 2).toLowerCase();
    } else {
      idioma = "en";
    }

    const mensajeOriginal = texto[0].trim();

    fetch("/api/traducir", {
      method: "POST",
      body: JSON.stringify({
        mensaje: mensajeOriginal,
        idioma: idioma
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.text();
      })
      .then(responseJson => {
        const json = JSON.parse(responseJson);

        this.textarea.current.value =
          mensajeOriginal + "\n" + this.separador + "\n" + json.data;

        this.setState({ mensajeOriginal });
      })
      .catch(error => {
      });
  }

  countMensajesSinLeer(idConversacion) {
    const { chats } = this.state;

    if (idConversacion !== "undefined" && chats.length > 0) {
      let i = 0;
      while (
        i < chats.length &&
        chats[i].conversations.idConversacion !== idConversacion
      ) {
        i++;
      }
      const cliente = chats[i].conversations.idCliente;
      const resultado = chats[i].conversations.mensajes.filter(val => {
        return val.id && val.enviadoPor === cliente && !val.leido;
      });

      if (resultado.length > 0) {
        return (
          <span className="time-meta pull-right numero-sin-leer">
            {resultado.length}
          </span>
        );
      }
    }
  }

  marcarLeidosMensajesClientes() {
    let { chats, clienteActivo, conversacionActiva, localActivo } = this.state;
    let i = 0;
    while (
      i < chats.length &&
      chats[i].conversations.idCliente !== clienteActivo.idCliente
    ) {
      i++;
    }
    if (i < chats.length) {
      for (let j = 0; j < chats[i].conversations.mensajes.length; j++) {
        if (
          chats[i].conversations.mensajes[j].id &&
          chats[i].conversations.mensajes[j].enviadoPor ===
            clienteActivo.idCliente
        ) {
          chats[i].conversations.mensajes[j].leido = true;
        }
      }
      this.setState({ chats }, () => {
        this.socket.emit("marcarLeidosCliente", {
          idCliente: clienteActivo.idCliente,
          idLocal: localActivo,
          idConversacion: conversacionActiva
        });
      });
    }
  }

  cambiarModo() {
    this.setState({ modoNotaPrivada: !this.state.modoNotaPrivada });
  }

  render() {
    const {
      chats,
      mensajes,
      clientes,
      user,
      plataforma,
      clienteActivo,
      canal,
      loading,
      modoNotaPrivada,
      whatsappActivo,
      conversacionActiva
    } = this.state;

    return (
      <div className="container app">
        {loading && <Spinner />}
        {/* <select onChange={this.selectChange}>
          {locales.map(local => (
            <option
              key={local.companies.idLocales}
              value={local.companies.idLocales}
            >
              {local.companies.denominacion}
            </option>
          ))}
        </select> */}
        <div className="app-one">
          <div className="col-sm-4 side">
            <div className="side-one">
              <div className="row heading"></div>

              {plataforma && (
                <Search
                  localActivo={this.state.localActivo}
                  plataforma={plataforma}
                  fetchChats={this.fetchChats}
                />
              )}
              <div
                className="row sideBar"
                id="scrollableDiv"
              >
                <InfiniteScroll
                  dataLength={chats.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore && !loading}
                  loader={<h4>Cargando conversaciones</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {
                    this.state.chats.map(chat => (
                      <div
                        className="row sideBar-body"
                        id={chat.conversations.idConversacion}
                        key={chat.conversations.idConversacion}
                        onClick={() => {
                          this.mostrarMensajes(
                            chat.conversations.idConversacion,
                            clientes[chat.conversations.idCliente].idCliente
                          );
                        }}
                      >
                        <div className="col-sm-3 col-xs-3 sideBar-avatar">
                          <div className="avatar-icon">
                            <img
                              src={
                                clientes[chat.conversations.idCliente]?.logo
                                  ? `https://imageswfz.s3-eu-west-1.amazonaws.com/mc/clientes/` +
                                    clientes[chat.conversations.idCliente]?.logo
                                  : require("./img/icono.png")
                              }
                              alt="icono"
                            />
                          </div>
                        </div>
                        <div className="col-sm-9 col-xs-9 sideBar-main">
                          <div className="row">
                            <div className="col-sm-8 col-xs-8 sideBar-name">
                              <span className="name-meta">
                                {clientes[chat.conversations.idCliente]?.nombre}
                              </span>
                            </div>
                            <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                              {this.countMensajesSinLeer(
                                chat.conversations.idConversacion
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </InfiniteScroll>
              </div>
              <br />
            </div>
          </div>
          <div className="col-sm-8 conversation">
            <div className="row heading">
              <div className="col-sm-2 col-md-1 col-xs-3 heading-avatar">
                <div className="heading-avatar-icon">
                  {clienteActivo && (
                    <img
                      src={
                        clienteActivo.logo
                          ? `https://imageswfz.s3-eu-west-1.amazonaws.com/mc/clientes/` +
                            clienteActivo.logo
                          : require("./img/icono.png")
                      }
                      alt="icono"
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-8 col-xs-7 heading-name">
                <div className="col-sm-8 col-xs-7 heading-name superior">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      `https://` +
                      plataforma +
                      `.wifreezone.com/listado/ficha_visitante/${clienteActivo?.fichaCliente}`
                    }
                  >
                    <span className="heading-name-meta">
                      {clienteActivo?.firstName} {clienteActivo?.lastName}
                    </span>
                  </a>
                  <span className="heading-online">
                    {clienteActivo &&
                      clienteActivo.ultimaConexion !== "En linea" &&
                      clienteActivo.ultimaConexion &&
                      moment(clienteActivo.ultimaConexion)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")}
                    {clienteActivo &&
                      clienteActivo.ultimaConexion === "En linea" &&
                      clienteActivo.ultimaConexion}
                    {clienteActivo &&
                      clienteActivo.ultimaConexion === "En linea" &&
                      " via Chat"}
                  </span>
                </div>
                <div className="col-sm-8 col-xs-7 heading-name inferior">
                  <span className="heading-online">{clienteActivo.email}</span>
                  {clienteActivo.telefono && (
                    <span className="heading-online">
                      Tel: {clienteActivo.telefono}
                    </span>
                  )}
                  {clienteActivo.fechaNacimiento && (
                    <span className="heading-online">
                      Edad:{" "}
                      {moment().diff(
                        moment(clienteActivo.fechaNacimiento, "YYYY-MM-DD"),
                        "years"
                      )}
                    </span>
                  )}
                  {clienteActivo.locale && (
                    <span className="heading-online">
                      Idioma: {clienteActivo.locale}
                    </span>
                  )}
                </div>
              </div>
              {/* <div className="col-sm-1 col-xs-1  heading-dot pull-right">
                <i
                  className="fa fa-ellipsis-v fa-2x  pull-right"
                  aria-hidden="true"
                />
              </div> */}
            </div>
            <div className="contenedor">
              <div className="row message" id="conversation">
                {mensajes.map((mensaje, i) => (
                  <div className="row message-body" key={this.getKey()}>
                    <div
                      className={
                        mensaje.notaPrivada
                          ? "col-sm-12 message-main-nota"
                          : mensaje.enviadoPor === user
                          ? "col-sm-12 message-main-sender"
                          : mensaje.enviadoPor === clienteActivo.idCliente
                          ? "col-sm-12 message-main-receiver"
                          : "col-sm-12 message-main-sender"
                      }
                    >
                      <div
                        className={
                          mensaje.notaPrivada
                            ? "notaPrivada"
                            : mensaje.enviadoPor === user
                            ? "sender"
                            : mensaje.enviadoPor === clienteActivo.idCliente
                            ? "receiver"
                            : "senderLocal"
                        }
                      >
                        {mensaje.idArchivo && (
                          <div className="message-file">
                            <a className="link-file" href={`${process.env.REACT_APP_API_DOMAIN}/api/descargaFichero?idConversacion=${conversacionActiva}&idMensaje=${mensaje.id}`} title="Haz clic para descargar" target="_blank" rel="noopener noreferrer">
                              <FontAwesomeIcon icon={faDownload} />
                              {" "}
                              {mensaje.mensaje}
                            </a> 
                          </div>
                        )}
                        { !mensaje.idArchivo && mensaje.tipoAdjunto !== "file" && mensaje.mensaje !== "" && mensaje.mensaje && (
                          <div className="message-text">
                            {mensaje.mensaje.split("\n").map((item, key) => {
                              return (
                                <span key={key}>
                                  {item}
                                  <br />
                                </span>
                              );
                            })}{" "}
                          </div>
                        )}
                        {mensaje.tipoAdjunto === "audio"  && (
                          <div className="message-audio">
                            <AudioPlayer
                            id="audio-reproductor"
                            customControlsSection={['MAIN_CONTROLS',
                              'VOLUME_CONTROLS']}
                            showSkipControls={false}
                            showJumpControls={false}
                              src={mensaje.url}
                            />
                          </div>
                        )}
                        {mensaje.tipoAdjunto === "file" && (
                          <div className="message-file">
                            <a className="link-file" href={mensaje.url} title="Haz clic para descargar" target="_blank" rel="noopener noreferrer">
                              <FontAwesomeIcon icon={faDownload} />
                              {" "}
                              {mensaje.mensaje}
                            </a>
                          </div>
                        )}
                        {mensaje.imgAdjunto && (
                          <div className="message-text">
                            <img
                              className="imagen"
                              alt=""
                              ref={myRefImg =>
                                (this.myRefImg[mensaje.imgAdjunto] = myRefImg)
                              }
                              src={
                                mensaje.imgPre && mensaje.imgAdjunto
                                  ? mensaje.imgPre
                                  : mensaje.imgAdjunto
                              }
                              onClick={() => {
                                this.cargarImagen(mensaje);
                              }}
                            />
                          </div>
                        )}
                        {
                          <span className="message-time pull-right">
                            {mensaje.notaPrivada
                              ? ""
                              : mensaje.enviadoPor !== clienteActivo.idCliente
                              ? "Enviado por"
                              : "Recibido por"}{" "}
                            {mensaje.source}{" "}
                            {moment(mensaje.horaEnvio)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")}{" "}
                            {mensaje.id &&
                              mensaje.enviadoPor !==
                                clienteActivo.idCliente && (
                                <div>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                      "" + (mensaje.leido ? "leidos" : "")
                                    }
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                      "" + (mensaje.leido ? "leidos" : "")
                                    }
                                  />
                                </div>
                              )}
                          </span>
                        }
                      </div>
                      {mensaje.id && !mensaje.imgAdjunto && !mensaje.tipoAdjunto &&
                        mensaje.enviadoPor === clienteActivo.idCliente &&
                        this.idioma &&
                        mensaje.idioma !== this.idioma && (
                          <input
                            id={"traducir" + mensaje.id}
                            type="button"
                            value="Traducir"
                            onClick={() => {
                              this.traducirMensajeCliente(mensaje.id);
                            }}
                          ></input>
                        )}
                    </div>
                    <div id={mensaje.id}></div>
                  </div>
                ))}
              </div>
              {clienteActivo && (
                <div
                  className="row reply"
                  id="responder"
                  ref={c => (this.responder = c)}
                >
                  <div className="reply-modo">
                    <input
                      type="button"
                      value="Responder"
                      className={`${!modoNotaPrivada ? "selected" : ""}`}
                      onClick={() => {
                        this.cambiarModo();
                      }}
                    ></input>
                    <input
                      type="button"
                      value="Nota interna"
                      className={`${modoNotaPrivada ? "selected" : ""}`}
                      onClick={() => {
                        this.cambiarModo();
                      }}
                    ></input>
                  </div>
                  <div className="col-sm-8 col-xs-8 reply-main">
                    <TextareaAutosize
                      /* innerRef={ref => {
                      this.textarea = ref;
                    }} */
                      ref={this.textarea}
                      onResize={this.onResize}
                      //value={this.state.mensaje}
                      className={`form-control ${
                        modoNotaPrivada ? "notaPrivada-mensaje" : ""
                      }`}
                      //onChange={this.handleChange("mensaje")}
                      rows={1}
                      id="mensaje"
                    />
                  </div>
                  {/*  
                {clienteActivo["email"] && (
                  <div className="col-sm-1 col-xs-1 reply-recording">
                    <i
                      className="fa fa-envelope fa-2x"
                      onClick={() => {
                        this.enviarMensajeMail();
                      }}
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div
                  className="col-sm-1 col-xs-1 reply-send"
                  onClick={() => {
                    this.enviarMensaje();
                  }}
                >
                  <i className="fa fa-send fa-2x" aria-hidden="true" />
                </div> */}
                  <select
                    className={`${
                      modoNotaPrivada ? "modoNotaPrivada-hidden" : "select-canal"
                    }`}
                    onChange={this.selectChangeCanal}
                    value={canal}
                  >
                    <option
                      key="chat"
                      disabled={
                        clienteActivo &&
                        this.socket.connected &&
                        clienteActivo.ultimaConexion === "En linea"
                          ? false
                          : true
                      }
                      value="chat"
                    >
                      Chat
                    </option>
                    <option
                      key="WhatsApp"
                      disabled={
                        !clienteActivo ||
                        !clienteActivo.telefono ||
                        !whatsappActivo
                      }
                      value="whatsapp"
                    >
                      WhatsApp
                    </option>
                    <option
                      key="sms"
                      disabled={
                        true
                        /* clienteActivo && clienteActivo.telefono ? false : true */
                      }
                      value="sms"
                    >
                      SMS
                    </option>
                    <option
                      key="email"
                      disabled={
                        clienteActivo && clienteActivo.email ? false : true
                      }
                      value="email"
                    >
                      Email
                    </option>
                    {/* <option key="facebook" value="facebook">
                      Facebook
                    </option> */}
                  </select>
                  {/* <div
                  className="col-sm-1 col-xs-1 reply-send"
                  onClick={() => {
                    this.enviarMensaje();
                  }}
                >
                  <i className="fa fa-commenting-o fa-2x" aria-hidden="true" />
                </div> */}

                  <div className="col-sm-2 col-xs-1 reply-send">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      size="lg"
                      onClick={() => {
                        this.enviar();
                      }}
                      className={"enviar"}
                    />
                    </div>
                  <div className="col-sm-1 boton-traducir">
                    <FontAwesomeIcon
                      icon={faLanguage}
                      size="lg"
                      onClick={() => {
                        this.traducir();
                      }}
                      className={`traducir ${
                        modoNotaPrivada ? "modoNotaPrivada-hidden" : ""
                      }`}
                    />
                    </div>
                  <div className="col-sm-1">
                    <div className={!clienteActivo ||
                        !clienteActivo.telefono ||
                        !whatsappActivo ? "modoNotaPrivada-hidden" : ""}>
                    <input
                      ref={this.fileRef}
                      onChange={this.handleChangeFile}
                      multiple={false}
                      type="file"
                      className={"input-oculto"}
                      hidden
                    />
                    <FontAwesomeIcon
                      icon={faUpload}
                      size="lg"
                      onClick={() => this.fileRef.current.click()}
                      className={`traducir ${
                        modoNotaPrivada ? "modoNotaPrivada-hidden" : ""
                      }`}
                    />
                    </div>
                  </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Chat);
